.sidebar_mar {
    .about_content {
        h2 {
            margin: 30px 0 20px;
        }

        .google_card {
            border: 1px solid $border_color;
            padding: 20px;
            border-radius: 12px;
            @include display(space-between, flex-start);
            gap: 25px;
            margin-bottom: 20px;

            .google_style {
                h4 {
                    margin-bottom: 10px;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 18px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    max-width: 64%;
                }
            }

            .switch_img {
                display: flex;
                gap: 15px;

                .switch {
                    margin-left: 10px;
                    width: 50px;
                    height: 25px;
                    background-color: #ccc;
                    border-radius: 25px;
                    position: relative;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }

                .switch.on {
                    background-color: $black;
                }

                .toggle {
                    width: 23px;
                    height: 23px;
                    background-color: $white;
                    border-radius: 50%;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    transition: left 0.3s;
                }

                .switch.on .toggle {
                    left: 26px;
                }
            }
        }
    }
}