/* Global styles for buttons */
button {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  outline: none;
}

/* Styles for the primary button */
button.primary {
  background-color: #4CAF50;
  /* Green color for the active button */
  color: white;
}

button.primary:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

/* Styles for the secondary button */
button.secondary {
  background-color: #f1f1f1;
  /* Light grey color for the inactive button */
  color: #333;
}

button.secondary:hover {
  background-color: #ddd;
  color: #222;
  transform: translateY(-2px);
}

/* Additional styling for better appearance */
button.primary,
button.secondary {
  min-width: 120px;
  display: inline-block;
  text-align: center;
}