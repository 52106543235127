.sidebar_mar {
  padding: 30px 30px 30px 340px;

  .header {
    .user-info {
      @include display(flex-end, center);
      gap: 10px;
      margin-bottom: 15px;

      span {
        font-size: 15px;
        letter-spacing: 0.3px;
      }
    }

    h2 {
      margin-bottom: 20px;
    }

    .margin_top {
      margin-top: 20px;
    }
  }

  .sidebar_det {
    background-color: $page_bg;
    @include display(space-between, center);
    padding: 20px 25px;
    border-radius: 8px;
    margin-bottom: 20px;

    @media ($breakpoint_LG) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .active_button {
      background-color: $page_bg;
      padding: 8px 18px;
      border: 1px solid $black;
      border-radius: 15px;
    }

    .project-select {
      // padding: 6px;
      width: 200px;
      border: 1px solid $border_color;
      border-radius: 8px;
      outline: none;
      font-size: 15px;
      font-weight: 500;
      font-family: inherit;
      font-style: normal;
      line-height: 18px;
      background-color: $white;
    }
  }
  .chat_main {
    max-width: 712px;
    margin: auto;
  }
  .scroll_content {
    overflow-y: scroll;
    // max-height: calc(100vh - 280px);
    max-height: calc(100vh - 380px);
    min-height: calc(100vh - 380px);

    @media ($breakpoint_XL) {
      padding: 0 50px;
    }

    @media ($breakpoint_LG) {
      padding: 0 30px;
    }

    @media ($breakpoint_MD) {
      padding: 0;
    }

    .content {
      @include display(center, center);

      .conversation {
        padding-bottom: 20px;
        width: 100%;

        .message {
          .message_content {
            text-align: end;
            margin-bottom: 10px;

            .user_chat {
              @include display(flex-end, center);

              p {
                text-align: end;
                border: 2px solid $page_bg;
                padding: 8px 10px;
                border-radius: 6px 6px 0 6px;
                margin-bottom: 5px;
              }
            }

            span {
              color: $forgot;
              font-size: 14px;
            }
          }

          .message-meta {
            @include display(flex-start, flex-start);
            flex-direction: column;
            gap: 10px;

            .ai_contents {
              background-color: $page_bg;
              padding: 16px;
              font-size: 15px;
              width: 100%;
              border-radius: 0 12px 12px 12px;
              font-weight: 400;
              line-height: 21px
            }

            .day_img {
              @include display(flex-end, center);
              width: 100%;

              p {
                color: $forgot;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .input-area {
    position: relative;
    bottom: 0px;
    width: 100%;
    margin-top: 10px;

    @media (max-width: 1300px) {
      max-width: 60%;
      left: 30%;
    }

    @media (max-width: 1200px) {
      max-width: 66%;
      left: 28%;
    }

    @media (max-width: 1150px) {
      max-width: 64%;
      left: 30%;
    }

    @media (max-width: 1080px) {
      max-width: 62%;
      left: 31%;
    }

    @media (max-width: 1050px) {
      max-width: 61%;
      left: 32%;
    }

    @media (max-width: 1010px) {
      max-width: 58%;
      left: 34%;
    }

    @media (max-width: 960px) {
      max-width: 60%;
      left: 34%;
    }

    @media (max-width: 910px) {
      max-width: 56%;
      left: 36%;
    }

    @media (max-width: 880px) {
      left: 37%;
    }

    @media (max-width: 830px) {
      max-width: 53%;
      left: 39%;
    }

    @media (max-width: 800px) {
      max-width: 51%;
      left: 41%;
    }

    @media ($breakpoint_MD) {
      max-width: 45%;
      left: 44%;
    }

    .add_circle {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    input {
      padding: 20px 70px 20px 70px;
      border-radius: 50px;
      outline: none;
      border: 2px solid $page_bg;
      width: 100%;
      font-weight: 500;
      font-family: inherit;
      font-size: 15px;
      line-height: 18px;
    }

    .send_icon {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .table_style {
    margin-top: 30px;

    .data-table {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;
      background-color: $white;

      thead {
        tr {
          .text_center {
            text-align: center;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 14px;
          }

          .text_center {
            text-align: center;

            img {
              width: 25px;
              height: 25px;
            }
          }

          .color_gray {
            color: $forgot;
          }
        }
      }
    }

    .data-table th,
    .data-table td {
      padding: 12px 15px;
      text-align: left;
      font-family: 'Inter', sans-serif;
    }

    .data-table th {
      border-bottom: 2px solid $page_bg;
    }

    .data-table tbody tr:nth-child(odd) {
      background-color: $white;
    }

    .data-table tbody tr:nth-child(even) {
      background-color: $page_bg;
    }

    button {
      padding: 6px 10px;
      background-color: $white;
      color: $black;
      border: 1px solid $black;
      border-radius: 4px;
      border-radius: 4px;
      cursor: pointer;
      font-family: 'Inter', sans-serif;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

.pdf_file {
  position: relative;

  &:hover {
    p {
      display: block;
    }
  }

  p {
    display: none;
    font-size: 14px;
    background-color: $page_bg;
    border-radius: 4px;
    padding: 6px;
    margin-bottom: 20px;
    width: 180px;
    text-align: center;
    position: absolute;
    top: -15px;
    left: -50px;
    cursor: pointer;
  }
}

.document-optionlist {
  position: absolute;
  background-color: $page_bg;
  border-radius: 4px;
  // height: 500px;
  width: 850px;
  padding: 1rem;
  bottom: 0;
  // width: 180px;
  // text-align: center;
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.chat-pdf-name {
  margin-top: 10px;
  border: 1px solid $page_bg;
  background-color: #0000001c;
  max-width: max-content;
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;

  a {
    color: #000ccc;
  }
}

.flex {
  display: flex;
}

.gap-5 {
  gap: 0.8rem;
}

.itemcenter {
  align-items: center;
}

.project_btn_styles {
  @include display(space-between, center);
  gap: 10px;
  margin-bottom: 15px;
  margin-top: 10px;

  button {
    color: $white;
    background-color: $black;
    padding: 10px 22px;
    border-radius: 8px;
    border: 1px solid $black;
  }
}

.document-option-item {
  position: relative;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;

  .upload-close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .upload-pdf {
    display: flex;

    input {
      padding: 0;
      border-radius: 0;
    }
  }
}
