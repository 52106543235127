$color_1: #0F0F0F;
$background-color_1: #F6F6F6;
$fade_color: #636363;

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: $background-color_1;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: width 0.3s;
    margin: 15px 0;
    padding: 10px;
    border-radius: 0px 60px 60px 0px;

    h1 {
        display: flex;
        gap: 10px;
        align-items: center;
    }
}

.open {
    width: 300px;
}

.sidebar.closed {
    width: 80px;

    .top-section {
        h1 {
            display: none;
        }
    }

    .nav-menu {
        ul {
            li {
                a {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

.top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    margin-top: 20px;
    margin-bottom: 60px;
}

.logo {
    width: 150px;
    // font-size: 24px;
    // font-weight: 700;
    // line-height: 29px;
    // color: $color_1;
}

.bars {
    font-size: 24px;
    cursor: pointer;
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
        list-style: none;
        padding: 0;

        li {

            a {
                padding: 12px 14px;
                text-decoration: none;
                color: $fade_color;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-family: inherit;
                font-size: 16px;
                line-height: 19px;
                font-style: normal;


                &.active {
                    background-color: #FFFFFF;
                    color: $color_1;
                    border-radius: 12px;
                    font-weight: 600;
                    height: 48px;
                    width: 240px;
                }

                span {
                    margin-left: 10px;
                    transition: opacity 0.3s;

                    .hidden {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .logout {
        position: absolute;
        bottom: 50px;
        text-align: center;
        color: $fade_color;

        .logout_div {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0 15px;
        }
    }

    .logout-section {
        margin-top: auto;
        
    }
}

// .active-link {
//     background-color: $white;
// }

.span_color {
    background-color: transparent;
}