.sidebar_mar {
  .header {
    .user-info {
      @include display(flex-end, center);
      gap: 10px;
      margin-bottom: 15px;

      span {
        font-size: 15px;
        letter-spacing: 0.3px;
      }
    }

    h2 {
      margin-bottom: 20px;
    }
  }

  form {
    .add_project_style {
      margin-bottom: 20px;


      h2 {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 1.75rem;
      }

      p {
        color: $forgot;
        font-weight: 500;
        font-size: 1.25rem;
      }
    }

    .two_input_flex {
      @include display(space-between, center);
      gap: 10px;
      margin-bottom: 15px;

      .label_input_flex {
        display: flex;
        flex-direction: column;
        width: 100%;


        label {
          margin-bottom: 6px;
          font-size: 15px;
          font-weight: 500;
          margin-left: 5px;
        }

        select {
          // padding: 16px;
          border: 2px solid $border_color;
          border-radius: 8px;
          outline: none;
          box-shadow: none;
          background-color: $white;
          font-weight: 500;
          line-height: 18px;
          font-family: inherit;
          color: $forgot;
          height: 50px;
        }

        input {
          padding: 16px;
          border: 2px solid $border_color;
          border-radius: 8px;
          outline: none;
          box-shadow: none;
          font-weight: 500;
          line-height: 18px;
          font-family: inherit;
          height: 50px;
        }
      }
    }

    .zip_flex {
      display: flex;
      flex-direction: column;
      width: 50%;

      label {
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 600;
        margin-left: 5px;
      }

      input {
        padding: 16px;
        border: 2px solid $page_bg;
        border-radius: 5px;
        font-weight: 500;
        line-height: 18px;
        font-family: inherit;
        height: 50px;
      }
    }
  }

  .folder_content {
    margin-top: 30px;
    border-bottom: 2px solid $page_bg;
    padding-bottom: 20px;

    h4 {
      font-size: 22px;
      margin-bottom: 6px;
      line-height: 26px;
      font-weight: 700;
    }

    p {
      color: $forgot;
      font-size: 15px;
      font-weight: 500;
      line-height: 15px;

      span {
        color: $black;
        font-weight: 500;
      }
    }
  }

  .cancel_create_btn {
    @include display(flex-end, center);
    gap: 10px;
    margin-top: 20px;

    .cancel_btn {
      background-color: $white;
      padding: 12px 24px;
      border: 1px solid $white;
      color: $forgot;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      font-family: inherit;
    }

    .create_btn {
      background-color: $black;
      padding: 12px 24px;
      border: 1px solid $black;
      color: $white;
      border-radius: 8px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      font-family: inherit;
    }
  }
}