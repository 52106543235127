.sidebar_mar {
    .about_content {
        margin-top: 30px;

        h2 {
            margin-bottom: 10px;
        }

        .integrate_style {
            color: $forgot;
            font-size: 15px;
        }

        p {
            margin-bottom: 20px;
            word-spacing: .3px;
        }
    }
}