.sidebar_mar {
    .header {
        .user-info {
            @include display(flex-end, center);
            gap: 10px;
            margin-bottom: 50px;

            span {
                font-size: 15px;
                letter-spacing: .3px;
            }
        }

        h2 {
            margin-bottom: 20px;
        }
    }

    .project_btn_style {
        @include display(space-between, flex-start);
        margin-block: auto;
        gap: 10px;
        margin-bottom: 30px;

        button {
            color: $white;
            background-color: $black;
            padding: 12px 24px;
            border-radius: 8px;
            border: 1px solid $black;
            font-size: 14px;
            font-weight: 700;
        }
    }

    .magic_content_btn {
        @include display(space-between, center);
        gap: 10px;
        padding-bottom: 25px;
        border-bottom: 2px solid $page_bg;
        margin-top: 25px;

        .label_input_flex {
            display: flex;
            flex-direction: column;
            width: 50%;

            h4 {
                margin-bottom: 12px;
                font-size: 18px;
                font-weight: 600;
                line-height: 21px;
            }

            p {
                font-size: 12px;
                color: $forgot;
                font-weight: 500;
                line-height: 14px;
            }
        }

        .active_btn_content {
            @include display(space-between, center);
            gap: 10px;
            width: 50%;

            .active_column {
                .active_button {
                    margin-bottom: 12px;
                    background-color: $white;
                    color: $black;
                    border: 1px solid $black;
                    padding: 6px 12px;
                    border-radius: 100px;
                    font-family: inherit;
                    font-size: 11px;
                    font-weight: 600;
                }

                p {
                    font-size: 12px;
                    color: $forgot;
                    font-weight: 500;
                }
            }

            img {
                filter: brightness(0.5);
                width: 20px;
            }
        }
    }
}