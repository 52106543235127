body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="email"],
input[type="password"],
input[type="text"],
select {
  -webkit-appearance: none;
  appearance: none;
}
select {
  background-color: transparent;
  background-image: url("assets/img/ar-down.svg");
  background-position: 50%;
  background-position-x: calc(100% - 15px);
  background-repeat: no-repeat;
  background-size: 10px;
  height: 40px;
  border-width: 1px;
  border-color: #dedede;
  padding-left: 1rem;
  padding-right: 2.5rem;
  width: 100%;
}
