.pdf-viewer {
  
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: #f1f1f1;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  // transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 9;
}

.pdf-viewer.open {
  transform: translateX(0);
}

.pdf-header {
  // padding: 10px;
  // background-color: #333;
  // color: white;
  // text-align: right;
  position: relative;
  button {
    position: absolute;
    top: 0;
    left: -3.5rem;
    z-index: 99;
  }
}

@media print {
  .rpv-core__text-layer .highlight {
      background-color: rgba(255, 240, 0, 0.5) !important;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
  }
  @media print {
    .highlight {
        background-color: yellow !important;
        -webkit-print-color-adjust: exact;
    }
}
}
.pdf-iframe {
  width: 100%;
  height: calc(100% - 40px);
}

.pdf-message-no-data{
  text-align: center;
  margin-top: 2rem;
}