.header-with-data {
  .google-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
    margin-right: 5px;
  }
  .user-data {
    display: flex;
    flex-direction: column;
  }
}

.text-danger {
  color: red;
  font-size: 13px;
  margin-left: 4px;
  padding: 5px 0;
}
