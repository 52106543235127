.content_section {
    padding-bottom: 10px;

    h1 {
        font-size: 30px;
        text-align: center;
        margin: 20px 0;
    }

    ul {
        ol {
            margin: 15px 0;
            font-size: 20px;
            font-weight: 600;
        }

        .list_item_first {
            margin-left: 30px;
            font: -webkit-mini-control;
            font-weight: 700;
            font-size: 15px;
        }

        .list_secound {
            font-size: 15px;
            margin: 10px 0 20px 45px;
            letter-spacing: .3s;
        }
    }
}