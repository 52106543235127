.inner {
  padding: 15px 40px 15px 0;
  height: 100vh;
  display: flex;

  @media ($breakpoint_MD) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 30px 0;
    height: auto;
  }

  .sec_bg {
    width: 30%;
    background-color: $page_bg;
    border-radius: 0 60px 60px 0;
    padding: 0 50px;
    height: 100%;
    @include display(center, center);
    flex-direction: column;
    border: none;

    // @media (min-width: 2000px) {
    //   @include display(center, center);

    // }

    @media ($breakpoint_MD) {
      width: 100%;
      border-radius: 0 0 30px 30px;
      align-items: center;
      padding: 20px 50px;
    }

    @media ($breakpoint_SM) {
      padding: 20px 30px;
    }

    .logotype {

      img{
        width: 150px;
        margin-bottom: 25px;
      }

      @media ($breakpoint_MD) {
        margin-bottom: 10px;
      }

      h3 {
        font-size: 21px;
        font-weight: 600;
      }
    }

    p {
      letter-spacing: 0.3px;
      font-weight: 400;
      max-width: 330px;
      line-height: 22px;
    }
  }

  .signup_bg {
    width: 70%;
    @include display(space-between, infinite);
    flex-direction: column;

    @media ($breakpoint_SM) {
      width: 90%;
    }

    .text_btn {
      @include display(flex-end, center);
      gap: 15px;
      margin-top: 15px;
      margin-bottom: 5px;

      @media ($breakpoint_MD) {
        margin-bottom: 20px;
      }

      h4 {
        font-size: 15px;
        font-weight: 500;
      }

      button {
        background-color: $black;
        padding: 10px 18px;
        border-radius: 6px;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid $black;
        font-family: inherit
      }
    }

    .signup_form {
      @include display(center, center);

      form {
        max-width: 400px;
        width: 100%;
        padding: 20px 20px 30px;
        border: 1px solid $border_color;
        border-radius: 12px;

        @media ($breakpoint_SM) {
          max-width: 100%;
        }

        h3 {
          margin-bottom: 15px;
          text-align: center;
          font-size: 1.75rem;
          line-height: 33px;
          font-weight: 700;
        }

        .toggle-btns {
          background-color: $page_bg;
          @include display(space-between, center);
          padding: 6px;
          border-radius: 6px;
          margin-bottom: 20px;
        }

        .d_flex {
          @include display(space-between, center);
          gap: 10px;
          margin-bottom: 12px;

          .form_group {
            input {
              width: 100%;
            }
          }
        }

        .numnber_otp {
          text-align: center;
          font-weight: 500;
          font-size: 15px;
          color: $forgot;
        }
        .resend-code{
          font-size: 13px;
          cursor: pointer;
        }
        .resend-code:hover{
          color: $black;
        }

        .form_group {
          flex-direction: column;
          display: flex;

          label {
            margin: 0 0 6px 8px;
            font-weight: 500;
            font-size: 16px;
          }

          .pass-label{
            margin-bottom: 15px;
          }

          //   input {
          //     padding: 12px;
          //     border-radius: 8px;
          //     border: 1px solid $border_color;
          //     outline: none;
          //     box-shadow: none;
          //   }

          textarea {
            padding: 12px;
            border-radius: 8px;
            border: 1px solid $border_color;
            outline: none;
            box-shadow: none;
            font-size: 14px;
          }


          .inline {
            position: relative;
            margin-top: 7px;

            .passwod_btn {
              position: absolute;
              right: 15px;
              background: $white;
              padding: 0;
              top: 9px;
            }
          }
        }

        .input_otp {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;

          input {
            width: 3em !important;
            border-radius: 6px;
            border: 1px solid $border_color;
            height: 3em;
            font-size: 15px;
            font-weight: 500;

            &:focus-visible {
              border: 1px solid $black;
            }
          }

          .space {
            width: 6px;
          }
        }

        p {
          text-align: end;
          font-size: 12px;
          color: $forgot;
          margin: 10px 0 16px;
          font-weight: 600;
        }

        .next_btn {
          position: relative;
          margin-top: 20px;

          button {
            background-color: $black;
            color: $white;
            padding: 13px;
            text-align: center;
            font-weight: 500;
            border: 1px solid $black;
            border-radius: 10px;
            width: 100%;
            font-family: inherit;
            font-size: 15px;
          }

          img {
            position: absolute;
            right: 14px;
            top: 7px;
          }
        }

        .separator {
          display: flex;
          align-items: center;
          text-align: center;
          margin: 20px 0;
          span{
            font-size: 13px;
            color: $forgot;
          }
        }

        .separator::before,
        .separator::after {
          content: "";
          flex: 1;
          border-bottom: 1px solid #ccc;
          margin: 0 10px;
        }

        .separator span {
          white-space: nowrap;
        }

        .goog_fac_btn {
          @include display(space-between, center);
          gap: 5px;

          @media ($breakpoint_450) {
            flex-direction: column;
            gap: 10px;
          }

          .button {
            width: 160px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $border_color;
            border-radius: 4px;
            padding: 7px 30px;
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-weight: 500;
            text-decoration: none;
            color: $forgot;
            background-color: $white;
            transition: background-color 0.3s, border-color 0.3s;

            img {
              margin-right: 5px;
            }
          }
        }
      }
    }

    p {
      font-size: 12px;
      text-align: center;
      margin-top: 10px;
      font-weight: 500;
      color: $forgot;

      @media ($breakpoint_MD) {
        margin-top: 20px;
      }
    }
  }
}

.contact_us {
  @include display(center, center);
}

.btn_flex {
  display: flex;
  gap: 10px;
  position: relative;

  .react-tel-input {
    width: 100%;

    .PhoneInputInput {
      padding: 8px;
      border: none;
      border-radius: 0 8px 8px 0;
      border-left: 1px solid #ddd;
    }
  }

  .mobilenumber {
    position: absolute;
    width: 73%;
    left: 95px;
    bottom: 1px;
    padding: 3px 10px 3px 20px;
    border: none;
    letter-spacing: 0.5px;
    height: 44px;
    font-size: 15px;
    border-radius: 8px;

    @media ($breakpoint_SM) {
      width: 71%;
    }

    @media ($breakpoint_450) {
      width: 69%;
    }

    @media ($breakpoint_ESM) {
      width: 63%;
    }

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  .react-tel-input {
    &::before {
      content: "";
      width: 2px;
      height: 26px;
      position: absolute;
      left: 102px;
      top: 9px;
      background-color: #cacaca;
      z-index: 1;
    }

    .special-label {
      display: none;
    }

    input {
      padding: 6px 10px 7px 51px;
      height: 46px;
      width: 100%;
      font-size: 15px;
      color: #000;
      border-radius: 8px;
      border: 1px solid $border_color;

      &:focus {
        box-shadow: none;
        border-color: #cacaca;
      }
    }

    .flag-dropdown {
      border: 1px solid transparent;
      background-color: transparent;
      border-right-color: #cacaca;

      &:focus {
        box-shadow: none;
      }

      .selected-flag {
        width: auto;

        &:hover {
          background-color: transparent;
        }
      }

      .country-list {
        height: 188px !important;

        .country {
          text-align: start;
        }

        .search {
          padding: 10px 20px 6px 10px;
          text-align: start;

          .search-box {
            margin: 0;
            padding: 7px 10px 7px 12px;
            height: 39px;
          }
        }
      }
    }
  }
}