.sidebar_mar {
  .document_content {
    // h2 {
    //   margin: 30px 0 20px;
    // }

    .two_input_flex {
      @include display(space-between, center);
      gap: 10px;
      margin-bottom: 15px;

      .label_input_flex {
        display: flex;
        flex-direction: column;
        width: 100%;

        label {
          margin-bottom: 6px;
          font-size: 15px;
          font-weight: 600;
          margin-left: 5px;
        }

        select {
          padding: 16px;
          border: 1px solid $border_color;
          border-radius: 8px;
          outline: none;
          box-shadow: none;
          background-color: $white;
          font-family: inherit;
          height: 50px;
          font-weight: 500;
          font-size: 15px;
          color: $forgot;
        }

        input {
          padding: 16px;
          border: 1px solid $border_color;
          border-radius: 8px;
          outline: none;
          box-shadow: none;
          font-family: inherit;
          height: 50px;
          font-weight: 500;
          font-size: 15px;
          color: $forgot;
        }
      }
    }


    .table_style {
      margin-top: 30px;


      .data-table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        background-color: $white;
        overflow: hidden;

        thead {
          tr {
            font-weight: 600;
            font-size: 14px;
            .text_center {
              text-align: center;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 12px;
              font-weight: 500;
            }

            .text_center {
              text-align: center;

              img {
                width: 25px;
                height: 25px;
              }
            }

            .color_gray {
              color: $forgot;
              a {
                text-decoration: none;
                // color: $black;
              }
            }
          }
        }
      }

      .data-table th,
      .data-table td {
        padding: 12px 15px;
        text-align: left;
        font-family: "Inter", sans-serif;
        // border-radius: 8px;
      }

      .data-table th {
        border-bottom: 2px solid $page_bg;
      }

      .data-table tbody tr:nth-child(odd) {
        background-color: $white;
      }

      .data-table tbody tr:nth-child(even) {
        background-color: $page_bg;
      }

      .data-table td:first-child { border-top-left-radius: 8px; }
      .data-table td:first-child { border-bottom-left-radius: 8px; }


      .data-table td:last-child { border-top-right-radius: 8px; }
      .data-table td:last-child { border-bottom-right-radius: 8px; }


      button {
        padding: 6px 12px;
        background-color: $white;
        color: $black;
        border: 1px solid $forgot;
        border-radius: 4px;
        border-radius: 4px;
        cursor: pointer;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 700px;
      }
    }
  }
}
